import '../styles/pages/affiliate.scss';
import * as React from "react"
import {HeadFC, Link, navigate, PageProps} from "gatsby"
import {useEffect, useMemo, useState, useRef} from "react";
import axios from 'axios';
import Slider from "react-slick";
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import {
    corporativeGoogleplay,
    corporativeApple,
    corporativeShape1,
    corporativeShape1Mob,
    corporativeMan,
    masterCardV2,
    VisaV2,
    BigLogoRight,
    AvatarImage,
    benefIcon1,
    benefIcon2,
    benefIcon3,
    corporativeLogo,
    modalClose
    
} from "../images";
import {toast, ToastContainer} from "react-toastify";
import HeaderCorporative from '../components/HeaderCorporative';

// type DataProps = {
//     path: string
// }

const settingsFeed = {
    dots: false,
    infinite: true,
    // speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
};



const AffiliatePage = ({}: PageProps) => {

    const [isWideScreen, setIsWideScreen] = useState<boolean>(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleModalOpen = () => {
        setModalStatus(true);
    }
    const handleModalClose = () => {
        setModalStatus(false);
    }

    const sendForm = (e) => {
        e.preventDefault();

        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            axios.post('https://podolyan.com.ua/aeer-affiliate-email-send.php', {email, message})
            .then((response) => {
                toast('Request is sent successfully', {
                    autoClose: 5000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setModalStatus(false);
                // console.log(response);
                
            }).catch((error) => {
                // console.error('Error:', error);
                toast('Please fill all fields', {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
        } else{
            toast('Email is invalid', {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        
    }
    
    useEffect(() => {
        if (typeof window !== 'undefined'){
            setIsWideScreen(window.innerWidth > 767);

            const handleResize = () => {
                setIsWideScreen(window.innerWidth > 767);
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
        
    }, []);


    const css = `
        html, body{
            background-color: #101010;
        }
    `;

    return (
        <main>
            <style>{css}</style>
            <div className="corporative">

                <HeaderCorporative image={corporativeLogo} actLink="affiliate" />

                <div className="articleBanner affiliateBanner" id="home">
                    <div className="articleContainer big">
                        <div className="affiliateBox">
                            <h1 className="affiliateTitle">Have a traffic?</h1>
                            <p className="affiliateSubtitle">IT IS TIME TO MAKE MONEY</p>
                            <p className="affiliateDescription">Your income doesn’t stop at the first payment. You will earn a commission every month for as long as the referred member stays active. This is not just a one-time deal. It's an ongoing revenue stream.</p>
                            <center>
                                <a onClick={() => handleModalOpen()}>
                                    <button className="corporativeLearnBtn corporativeBtn">
                                        <span>Become a Partner</span>
                                    </button>
                                </a>
                            </center>
                            <div className="affiliateMan">
                                <img src={corporativeMan} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <picture className="corporativeFirstShape">
                    <source srcSet={corporativeShape1} media="(min-width: 768px)" />
                    <img src={corporativeShape1Mob} />
                </picture>

                <div className="affiliateBenef">
                    <div className="affiliateBenefWrap">
                        <h2 className="affiliateBenefTitle">Why Join our <br /> AFFILIATE PROGRAM</h2>
                        <div className="affiliateBenefBox">
                            <div className="affiliateBenefItem">
                                <span className="affiliateBenefIcon">
                                    <img src={benefIcon1} alt="" />
                                </span>
                                <span className="affiliateBenefName">HIGH REVENUE SHARE</span>
                                <p className="affiliateBenefDescr">With monthly subscription prices ranging from $9.99 to $99.99, there's a fantastic opportunity to earn considerable income. The more members you refer, the more you earn.</p>
                            </div>

                            <div className="affiliateBenefItem">
                                <span className="affiliateBenefIcon">
                                    <img src={benefIcon2} alt="" />
                                </span>
                                <span className="affiliateBenefName">RECURRING PAYOUTS</span>
                                <p className="affiliateBenefDescr">Your income doesn’t stop at the first payment. You will earn a commission every month for as long as the referred member stays active. This is not just a one-time deal. It's an ongoing revenue stream.</p>
                            </div>

                            <div className="affiliateBenefItem">
                                <span className="affiliateBenefIcon">
                                    <img src={benefIcon3} alt="" />
                                </span>
                                <span className="affiliateBenefName">ADVANCED TRACKING SYSTEM</span>
                                <p className="affiliateBenefDescr">Our sophisticated tracking system ensures that you receive credit for all of the traffic you send. You can rely on accurate and transparent reporting for all your referred sales.</p>
                            </div>
                        </div>

                        <center>
                            <a onClick={() => handleModalOpen()}>
                                <button className="corporativeLearnBtn corporativeBtn">
                                    <span>Become a Partner</span>
                                </button>
                            </a>
                        </center>

                        <p className="affiliateBenefQues">If you have questions and want more information, write to: <a href="mailto:сeo@feelgrow.ai">сeo@feelgrow.ai</a></p>
                    </div>
                </div>

                <div className={`affiliateModal ${modalStatus ? 'active' : ''}`}>
                    <div className="affiliateModalBox">
                        <span className="affiliateModalClose" onClick={() => handleModalClose()}>
                            <img src={modalClose} alt="" />
                        </span>
                        <span className="affiliateModalTitle">Make a request</span>
                        <p className="affiliateModalSubtitle">Please tell us about you and your Promotion Plan</p>
                        <ol className="affiliateModalList">
                            <li>Website / Social Profile URLs?</li>
                            <li>What’s your audience (size, geo, age, gender, interests, etc.)?</li>
                            <li>What promotion methods will be using?</li>
                            <li>Any other important information we should know to faster the approval process?</li>
                        </ol>

                        <form onSubmit={(e) => sendForm(e)} className="affiliateModalForm">
                            <div className="affiliateModalInput">
                                <label htmlFor="email">E-mail</label>
                                <input type="text" id="email" placeholder="Enter your e-mail"
                                    onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="affiliateModalInput">
                                <label htmlFor="message">Message</label>
                                <textarea name="" id="message" placeholder="Message 0/600"
                                    onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>
                            <center>
                                <button type="submit" className="corporativeLearnBtn corporativeBtn">
                                    <span>Send Request</span>
                                </button>
                            </center>
                        </form>
                    </div>
                </div>
                <div className={`affiliateOverlay ${modalStatus ? 'active' : ''}`} onClick={() => handleModalClose()}></div>
                
                <footer className="corporativeFooter">
                    <div className="corporativeContainer">
                        <div className="corporativeFooterBox">
                            <div className="corporativeFooterLeft">
                                <div className="corporativeFooterUser">
                                    <img src={AvatarImage} alt="" />
                                </div>
                                <div className="corporativeFooterInfo">
                                    <span>CEO</span>
                                    <b>Andrew Filatov</b>
                                    <p>My goal is to create a product that <br /> helps people improve themselves</p>
                                </div>
                            </div>
                            <div className="corporativeFooterCenter">
                                <div className="corporativeFooterSoc desk">
                                    <a>
                                        <img src={masterCardV2} alt="" />
                                    </a>
                                    <a>
                                        <img src={VisaV2} alt="" />
                                    </a>
                                </div>
                                <div className="corporativeFooterPayment">
                                    <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140">
                                        <img src={corporativeApple} alt="" />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140">
                                        <img src={corporativeGoogleplay} alt="" />
                                    </a>
                                </div>

                                <div className="corporativeFooterNav">
                                    <Link to="/terms">Terms of User</Link>
                                    <Link to="/privacy">Privacy policy</Link>
                                    <Link to="/cookie">Cookie policy</Link>
                                    <Link to="/cancel">Unsubscribe</Link>
                                </div>
                            </div>
                            <div className="corporativeFooterRight">
                                <div className="corporativeFooterSoc mob">
                                    <a>
                                        <img src={masterCardV2} alt="" />
                                    </a>
                                    <a>
                                        <img src={VisaV2} alt="" />
                                    </a>
                                </div>
                                <img src={BigLogoRight} alt="" className="corporativeFooterLogo" />
                                <p>© AEER PLATFORM INC <br />
                                8 THE GREEN STE A DOVER, DE 19901</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

            <ToastContainer/>
        </main>
    )
}

export default AffiliatePage

export const Head: HeadFC = () => <title>AEER Platform</title>
